.app {
  display: flex;
  justify-content: center;
}

.content {
  width: min(clamp(30vw, 400px, calc(90% - 1rem)), 560px);
  margin-left: -1.2rem;
  margin-top: 2rem;
}

.content h1 {
  margin: 0;
  margin-bottom: 0.8rem;
  font-weight: 700;
}

.app a {
  white-space: nowrap;
}

.slink {
  text-decoration: none;
  font-weight: 500;
  color: var(--gray);
  margin-right: 1.2rem;
  padding: 0.4rem 1rem;
  border-radius: 0.6rem;
}

.slink__active {
  color: var(--text);
  background-color: var(--bg2);
}

.content__box {
  background-color: var(--bg2);
  border-radius: 0.6rem;
  width: 100%;
  margin-top: 1.4rem;
  padding: 0.4rem 1rem;
}

.content__box h3 {
  margin: 0.4rem 0;
}

.updatedAt {
  font-size: small;
  font-weight: 500;
  color: var(--gray);
  margin-left: 0.4rem;
}

.logo__mobile {
  display: none;
}

.banner {
  width: calc(100% + 2rem);
  border-radius: 0.6rem;
  margin-top: 1.4rem;
}

.comingsoon {
  color: var(--gray);
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 1.6rem 0;
}
.comingsoon i {
  font-size: 24pt;
}
.comingsoon p {
  margin: 0.2rem;
  font-weight: 600;
}

.centered__container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 659px) {
  .logo__mobile {
    display: inline;
    width: 33vw;
    padding-bottom: 1rem;
  }
}
