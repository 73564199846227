.search {
  background-color: var(--bg2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  border-radius: 0.6rem;
  margin: 1rem 0;
  width: 100%;
  padding: 0.3rem 1rem;
}

.search i {
  font-size: x-large;
  padding: 0.4rem;
}

.search input {
  background-color: var(--bg2);
  width: 100%;
  border: 0;
  outline: none;
  font-size: medium;
  color: var(--text);
}
