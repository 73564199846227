@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto+Mono&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg: #f7f7f7;
  --bg2: white;
  --text: #0d003f;
  --main: #3100e0;
  --main-op: #3100e020;
  --gray: #8b83a7 /*#75808A*/;

  --success: #5dd360;
  --success-op: #5dd36020;
  --error: #f97a6f;
  --error-op: #f97a6f20;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  color: var(--text);
  overflow-x: hidden;
  padding-bottom: 3rem;
}

a {
  color: var(--main);
}

.flex__left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.flex__center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex__space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
}

/* CUSTOM SCROLLBAR */
body::-webkit-scrollbar {
  background-color: var(--bg);
  width: 16px;
}
body::-webkit-scrollbar-track {
  background-color: var(--bg);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--main);
  border-radius: 16px;
  border: 4px solid var(--bg);
}
body::-webkit-scrollbar-button {
  display: none;
}

@media only screen and (max-width: 659px) {
  body {
    padding-bottom: 5rem;
  }
}
