.search__box {
  background-color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  border-radius: 0.6rem;
  margin: 1rem 0;
  margin-bottom: 3.4rem;
}

.search__box i {
  font-size: x-large;
  padding: 0.4rem;
}

.search__box input {
  background-color: var(--bg);
  width: 100%;
  border: 0;
  outline: none;
  font-size: large;
}

.coingecko__attribution {
  margin-left: 0.15rem;
  font-size: small;
}

.chain__status {
  padding-bottom: 2.4rem;
}

.chain__status__container {
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
}

.chain__status__container div {
  display: block;
  width: calc(50% - 1rem);
}

.chain__status h1 {
  text-align: center;
  color: var(--main);
  font-weight: 900;
  font-size: 28pt;
  line-height: 28pt;
  margin: 0;
  margin-top: 2.4rem;
}

.chain__status p {
  text-align: center;
  font-weight: 600;
  margin: 0;
}
